<template>
  <div>
    <!-- WELCOME -->
    <section class="pt-4 pt-md-11 bg-colored" style="padding-top: 12rem !important">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12 col-md-5 col-lg-6 order-md-2">

            <!-- Image -->
            <img src="assets/img/hybrid-system.gif" class="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0" alt="Asolar product image" data-aos="fade-up" data-aos-delay="100">

          </div>
          <div class="col-12 col-md-7 col-lg-6 order-md-1" data-aos="fade-up">

            <!-- Heading -->
            <h1 class="display-3 text-center text-md-start">
              <span class="text-primary">Hybrid</span> Solar System<br>
            </h1>

            <!-- Text -->
            <p class="text-center text-md-start text-muted mb-6 mb-lg-8 text-white">
              Hybrid Solar Systems that convert direct current (DC) output of a photovoltaic (PV) solar panel into alternating 220 - 240V current (AC) to power household appliances.
            </p>

            <!-- Buttons -->
            <div class="text-center text-md-start">
              <a href="#section1" class="btn btn-primary me-1 lift">
                Learn More <i class="fe fe-arrow-down ms-3"></i>
              </a>
              <a href="#requestQuote" class="fw-bold text-decoration-none text-white">
                Request a Quote <i class="fe fe-arrow-right ms-1"></i>
              </a>
            </div>

          </div>
        </div> <!-- / .row -->
      </div> <!-- / .container -->
    </section>
    
    <!-- HS System -->
    <section class="py-8 py-md-11 border-bottom" v-for="(item) in hybridsystems" :key="item.id" :id="`section${item.id}`">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12 col-md-7 col-lg-6" data-aos="fade-right">
            <h2>
              Features<br>
              <span class="text-primary">{{ item.title }}</span>.
            </h2>
            <!-- <p class="fs-lg text-muted mb-6">
              We've built well over a dozen Bootstrap themes and sold tens of thousands of copies.
            </p> -->
            <div class="d-flex" v-for="(feat, index) in item.features" :key="index">
              <div class="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i class="fe fe-check"></i>
              </div>
              <p v-html="feat">
              </p>
            </div>
          </div>
          <div class="col-12 col-md-5 col-lg-6">
            <div class="w-md-150 w-lg-130 position-relative" data-aos="fade-left">
              <img :src="item.image" class="screenshot img-fluid img-skewed-item" alt="...">
            </div>
          </div>
        </div>
      </div>
    </section>

    <Brands />

    <section class="pt-8 pt-md-11 bg-gradient-light-white" id="requestQuote">
      <div class="container">
        <div
          class="row align-items-center justify-content-between mb-8 mb-md-11"
        >
          <div class="col-12 col-md-5 order-md-1" data-aos="fade-left">
            <h2>
              We will definitely reachout <br />
              <span class="text-success"
                >because we care about you
                <span
                  data-typed='{"strings": ["developers.", "founders.", "designers."]}'
                ></span
              ></span>
            </h2>
            <!-- Text -->
            <p class="fs-sm text-muted mb-6" v-show="!selectedSystem">
              ASOLAR was founded in 2016 to partake in an initiative to bring
              power to off-grid communities Our products and services are
              fundamentally customer-focused. Home energy systems to commercial
              projects. Cost optimization, minimize risk and provide
              comprehensive support before and after sales.
            </p>
            <div class="text-center" v-show="selectedSystem">
              <img :src="selectedSystem" alt="Package" class="img-fluid mx-auto d-block">
            </div>
          </div>
          <div class="col-12 col-md-7 col-lg-7" data-aos="fade-right">
            <!-- Card -->
            <div class="card shadow-light-lg lift lift-lg">
              <!-- Body -->
              <div class="card-body">
                <!-- Form -->
                <form @submit.prevent="sendRequest()" v-if="!success">
                  <div class="row justify-content-center align-items-center mb-2">
                    <div class="col">
                      <div class="form-floating">
                        <input
                          type="text"
                          class="form-control form-control-flush"
                          id="cardName"
                          placeholder="Christopher"
                          v-model="fields.fullname"
                        />
                        <label for="cardName"
                          >Fullname <span style="color: red">*</span></label
                        >
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-floating">
                        <input
                          type="text"
                          class="form-control form-control-flush"
                          id="cardPhone"
                          placeholder="Phone"
                          v-model="fields.phone"
                        />
                        <label for="cardPhone"
                          >Phone <span style="color: red">*</span></label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-center align-items-center mb-2">
                    <div class="col">
                      <div class="form-floating">
                        <input
                          type="email"
                          class="form-control form-control-flush"
                          id="cardEmail"
                          placeholder="Email"
                          v-model="fields.email"
                        />
                        <label for="cardEmail">Email</label>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-floating">
                        <select
                          name=""
                          id="cardType"
                          class="form-control form-control-flush"
                          v-model="fields.id"
                          @change="switchImage(fields.id)"
                        >
                          <option value="" selected disabled>Select an option</option>
                          <option v-for="item in hybridsystems" :value="item.id" :key="item.id">
                            {{ item.title }}
                          </option>
                        </select>
                        <label for="cardSystem"
                          >Select system
                          <span style="color: red">*</span></label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-center align-items-center mb-2">
                    <div class="col">
                      <div class="form-floating">
                        <select
                          name=""
                          id="cardState"
                          class="form-control form-control-flush"
                          v-model="fields.state"
                          @change="updateLGA"
                        >
                          <option value="" disabled>Select an option</option>
                          <option
                            v-for="state in states"
                            :value="state.alias"
                            :key="state.alias"
                          >
                            {{ state.state }}
                          </option>
                        </select>
                        <label for="cardSystem"
                          >Select Current State
                          <span style="color: red">*</span></label
                        >
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-floating">
                        <select
                          name=""
                          id="cardLGA"
                          class="form-control form-control-flush"
                          v-model="fields.lga"
                        >
                          <option value="" disabled>Select an option</option>
                          <option
                            v-for="lga in selectedLGA"
                            :value="lga"
                            :key="lga"
                          >
                            {{ lga }}
                          </option>
                        </select>
                        <label for="cardSystem"
                          >Select LGA <span style="color: red">*</span></label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-center align-items-center mb-2">
                    <div class="col">
                      <div class="form-floating">
                        <input
                          type="text"
                          class="form-control form-control-flush"
                          id="cardOccupation"
                          placeholder="Occupation"
                          v-model="fields.occupation"
                        />
                        <label for="cardSystem"
                          >Occupation <span style="color: red">*</span></label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-center align-items-center">
                    <div class="col">
                      <div class="form-group">
                        <label for="cardSystem" class="address-label">Address</label>
                        <textarea
                          name=""
                          rows="3"
                          id="cardAddress"
                          v-model="fields.address"
                          placeholder="1 Winners way"
                          class="form-control"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-center mt-3">
                    <button
                      class="btn w-100 btn-success lift"
                      :disabled="loading"
                      type="submit"
                    >
                      {{ loading ? "Loading..." : "Request a Quote" }}
                    </button>
                  </div>
                </form>
                <div v-else>
                  <img
                    src="assets/img/success.svg"
                    width="50%"
                    class="mx-auto d-block mb-5"
                  />
                  <p class="fs-md text-center text-muted mb-6">
                    Your interest has been recorded successfully, someone from
                    our customer support team will reach out to you shortly.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- / .container -->
    </section>

    <Faqs />

    <!-- SHAPE -->
    <div class="position-relative">
      <div class="shape shape-bottom shape-fluid-x text-gray-200">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z"
            fill="currentColor"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import Brands from "@/components/Brands.vue";
import Faqs from "@/components/Faqs.vue";
import GeneralService from "@/services/GeneralService";
import { mapState } from "vuex";

export default {
  name: "HybridSystem",
  components: {
    Brands,
    Faqs,
  },
  computed: {
    ...mapState(["loading"]),
    selectedLGA() {
      let results = this.states.find(
        (state) => state.alias === this.fields.state
      );
      return results ? this.sortBy(results.lgas) : [];
    }
  },
  data() {
    return {
      success: false,
      states: this.sortBy(this.$globalFunc.locations(), 'name'),
      fields: {
        system: "",
        state: "",
        lga: "",
        id: "",
      },
      selectedSystem: "",
      hybridsystems: [
        {
          id: 1,
          title: "HS MiniGrid Systems",
          features: [
            "Integrated display, Remote 2G access", 
            "Lithium balancing, Priority selection AC/Solar", 
            "Individually controlled AC outputs overload, Over current",
            "Short-circuit, Deep-discharge, Wrong-polarity protection",
            "USB charging port, PAYG remote control"
          ],
          image: "/img/hybrid/HS-Mini-Grid.png"
        },
        {
          id: 2,
          title: "HS-12 Systems",
          features: [
            "1.2kWh battery",
            "265W solar panel",
            "Can power 12 hours of:<br>TV,<br>Decoder,<br>Lights,<br>and Fan"
          ],
          image: "/img/hybrid/ASOLAR-HS-12.png"
        },
        {
          id: 3,
          title: "HS-24 Systems",
          features: [
            "500W Solar Panel",
            "2.4kWh Battery",
            "Outright Payment",
            "2-Year Warranty",
            "Can power 24/7 hours of:<br>TV,<br>Decoder,<br>Lights,<br>and Fan",
          ],
          image: "/img/hybrid/ASOLAR_HS_24.png"
        },
        {
          id: 4,
          title: "HS-48 Systems",
          features: [
            "1KW Solar Panel",
            "4.8kWh Battery",
            "Outright Payment",
            "2-Year Warranty",
            "Can power 24/7 hours of:<br>TV,<br>Decoder,<br>Lights,<br>2 Fans,<br>Small Fridge",
          ],
          image: "/img/hybrid/ASOLAR-HS-48.png"
        },
        {
          id: 5,
          title: "HS-96 Systems",
          features: [
            "2KW Solar Panel",
            "9.6kWh Battery",
            "Outright Payment",
            "2-Year Warranty",
            "Can power 24/7 hours of:<br>TVs,<br>Decoder,<br>Lights,<br>4 Fans,<br>Fridge,<br>Washing machine (during the day)",
          ],
          image: "/img/hybrid/ASOLAR_HS-96.png"
        },
        {
          id: 6,
          title: "HS-192 Systems",
          features: [
            "4KW Solar Panel",
            "19.2kWh Battery",
            "Outright Payment",
            "2-Year Warranty",
            "Can power 24/7 hours of:<br>TVs,<br>Decoder,<br>Lights,<br>Fans,<br>Fridge,<br>Freezer,<br>No need for NEPA or Generator"
          ],
          image: "/img/hybrid/ASOLAR_HS-192.png"
        }
      ]
    };
  },
  methods: {
    sortBy(array, type = null) {
      if (type) {
        return array.sort((a, b) => a.state < b.state ? -1 : (a.name > b.name ? 1 : 0))
      }
      return array.sort((a, b) => a < b)
    },
    switchImage(itemId) {
      const system = this.hybridsystems.find(system => system.id === itemId)
      console.log(system)
      this.fields.system = system.title
      this.selectedSystem = system.image
    },
    sendRequest() {
      if (this.validateData()) {
        this.$store.dispatch("setLoading", true);
        GeneralService.indicateRequest(this.fields)
          .then((result) => {
            if (result.status) {
              this.success = result.status;
              this.showAlert("Successful", result.message, "success");
            }
          })
          .catch((err) => {
            if (err.response === undefined) {
              this.showAlert(
                "Error occured",
                "Oops! took long to get a response",
                "warning"
              );
            } else {
              this.showAlert(
                err.response.data.status == false
                  ? "Error occured"
                  : "Server error",
                err.response.data.status == false
                  ? err.response.data.message
                  : "Internal server error",
                "warning"
              );
            }
          })
          .finally(() => {
            this.$store.dispatch("setLoading", false);
          });
      }
    },
    validateData() {
      if (this.fields.fullname) {
        if (this.fields.phone) {
          if (this.fields.system) {
            if (this.fields.state) {
              if (this.fields.lga) {
                if (this.fields.occupation) {
                  return true;
                } else {
                  this.showAlert(
                    "Validation error",
                    "Provide your occupation",
                    "warning"
                  );
                }
              } else {
                this.showAlert(
                  "Validation error",
                  "Select your lga state",
                  "warning"
                );
              }
            } else {
              this.showAlert(
                "Validation error",
                "Select your current state",
                "warning"
              );
            }
          } else {
            this.showAlert(
              "Validation error",
              "Select your choice of system",
              "warning"
            );
          }
        } else {
          this.showAlert(
            "Validation error",
            "Phone number is mandatory",
            "warning"
          );
        }
      } else {
        this.showAlert(
          "Validation error",
          "Kindly provide your full name",
          "warning"
        );
      }
    },
    updateLGA() {},
    showAlert(title, text, type) {
      this.$fire({
        title,
        text,
        type: type ? type : "warning",
      });
    },
  },
};
</script>
<style scoped>
.text-green {
  color: #05895a;
}
.address-label {
  font-size: .75rem;
  color: #869ab8;
}
.form-floating>.form-control:focus, .form-floating>.form-control:not(:placeholder-shown) {
  padding-bottom: .125rem;
  padding-top: 1.625rem;
  font-size: 16px;
}
.form-control {
  font-size: 16px;
}
.shadow-light-lg {
  box-shadow: 0 0.5rem 0.7rem rgb(5 137 90 / 58%) !important;
}
small {
  font-size: 14px;
  letter-spacing: 0px;
}
.double-line-through {
  text-decoration-line: line-through; 
  text-decoration-style: double;
}
.alert.alert-success {
  background-color: #42ba96;
  border-color: #42ba96;
  color: #fff;
  padding: 0.2rem 0;
  border-radius: 0px;
}
span.text-primary {
  color: #05895a !important;
  font-weight: bold;
}
.bg-colored {
  background: linear-gradient(to bottom, #fed529 70%, #05895a 120%);
  height: 100vh;
}
.text-white {
  color: #fff !important;
}
</style>